import React from "react";
import { m } from "framer-motion";
import RealisationCTABlock from "components/Block/RealisationCTABlock";
import NodeRealisationList from "components/Realisation/NodeRealisationList";
import { fadeIn } from "lib/variants";
import Headings from "components/Headings";

export default function RealizationsSection({ nodes, cta = null }) {
  return (
    <m.section variants={fadeIn} className="relative py-16 2xl:py-28">
      <div
        className="absolute top-0 inset-x-0 h-1/4 min-h-[24rem] bg-dark"
        aria-hidden="true"
      />

      <div className="relative container px-4 lg:px-12 mx-auto text-light">
        <Headings
          pretitle="On touche avec les yeux 👀"
          title="Quelques réalisations de l'agence"
        />

        <div className="mt-40 lg:mt-20">
          <NodeRealisationList
            nodes={
              cta
                ? [...nodes, <RealisationCTABlock block={cta} showLink />]
                : nodes
            }
          />
        </div>
      </div>
    </m.section>
  );
}
